exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-alltag-eingewoehnung-index-tsx": () => import("./../../../src/pages/alltag/eingewoehnung/index.tsx" /* webpackChunkName: "component---src-pages-alltag-eingewoehnung-index-tsx" */),
  "component---src-pages-alltag-index-tsx": () => import("./../../../src/pages/alltag/index.tsx" /* webpackChunkName: "component---src-pages-alltag-index-tsx" */),
  "component---src-pages-alltag-mahlzeiten-index-tsx": () => import("./../../../src/pages/alltag/mahlzeiten/index.tsx" /* webpackChunkName: "component---src-pages-alltag-mahlzeiten-index-tsx" */),
  "component---src-pages-alltag-tagesablauf-index-tsx": () => import("./../../../src/pages/alltag/tagesablauf/index.tsx" /* webpackChunkName: "component---src-pages-alltag-tagesablauf-index-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-paedagogisches-konzept-tsx": () => import("./../../../src/pages/paedagogisches-konzept.tsx" /* webpackChunkName: "component---src-pages-paedagogisches-konzept-tsx" */),
  "component---src-pages-standorte-index-tsx": () => import("./../../../src/pages/standorte/index.tsx" /* webpackChunkName: "component---src-pages-standorte-index-tsx" */),
  "component---src-pages-standorte-kita-4-elemente-index-tsx": () => import("./../../../src/pages/standorte/kita-4-elemente/index.tsx" /* webpackChunkName: "component---src-pages-standorte-kita-4-elemente-index-tsx" */),
  "component---src-pages-standorte-kita-die-gruenschnaebel-index-tsx": () => import("./../../../src/pages/standorte/kita-die-gruenschnaebel/index.tsx" /* webpackChunkName: "component---src-pages-standorte-kita-die-gruenschnaebel-index-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */)
}

